// Colors
$primary: #e5261f;
$primary-dark: #a01b16;
$secondary: #f88379;
$black: #2f2f41;
$white: #ffffff;
$white-offset: #fff6f8;
$steel: #5c5a5a;

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: lighten($primary, 10%);
$link-hover-decoration: underline;

// Fonts
$font-family-base: Helvetica, Arial, sans-serif, -apple-system;
$font-family-heading: "Playfair Display", serif, -apple-system;

// Footer
$footer-background-color: $primary;
$footer-text-color: $white;
$sub-footer-background-color: darken($primary, 10%);
$sub-footer-text-color: $white;

// Bootstrap
@import "bootstrap-variables";
@import "bootstrap/bootstrap-reboot";
@import "bootstrap/bootstrap-grid";
// @import 'bootstrap/bootstrap'; // Uncomment this line to import the entire Bootstrap library

// Libraries
@import "libraries/hamburgers/hamburgers";

// Components
@import "components/type";
@import "components/page";
@import "components/header";
@import "components/footer";
@import "components/sub-footer";
@import "components/logo";
@import "components/main-menu";
@import "components/main-menu-mobile";
@import "components/hamburger";
@import "components/buttons";
@import "components/call";
@import "components/title";
@import "components/content";
@import "components/intro";
@import "components/intro-image";
@import "components/strip";
@import "components/feature";
@import "components/social";
@import "components/fonts"; // Uncomment this line to self host font

// Pages
@import "pages/page-home";
@import "pages/page-teams";
@import "pages/page-issue";

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: $font-family-base;
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 1.3;
  }
}

.logo-mobile {
  width: 200px;
}

@media (min-width: 768px) {
  .intro {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
